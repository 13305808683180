import * as React from 'react';
import { Link, navigate } from 'gatsby';
import { Heading, Text, Flex } from '@chakra-ui/react';
import { RouteComponentProps } from '@reach/router';
import './css/index.css';
import { PrimaryButton, SecondaryButtonB } from '../components/buttons';

const NotFound = (props: RouteComponentProps): JSX.Element => {
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Flex
        marginTop="15%"
        height="45vh"
        className="inner-container"
        direction="column"
        align="center"
      >
        <Flex justify="space-around">
          <Heading
            height="75px"
            fontSize={['30pt', '39pt', '40pt']}
            // fontFamily="Catamaran Bold"
          >
            404 Not Found
          </Heading>
        </Flex>
        <Flex justify="space-around">
          <Text height="75px">
            We couldn't find the page you were looking for{' '}
          </Text>
        </Flex>
        <Flex width="50%" justify="space-evenly">
          <SecondaryButtonB onClick={goBack} type="button">
            Go Back
          </SecondaryButtonB>
          <Link to="/">
            <PrimaryButton type="button">Go Home</PrimaryButton>
          </Link>
        </Flex>
      </Flex>
    </>
  );
};

export default NotFound;
